import { combineReducers } from 'redux'
import connectWallet from './connectWallet'
import transaction from './transaction'
import general from './general'

export default combineReducers({
  connectWallet,
  transaction,
  general
})

import React from 'react'

const Loading = ({ inline = false }) => {
  if (inline) return <Logo width={30} height={30} />
  return (
    <div className="w-full h-screen-130 flex items-center justify-center">
      <Logo />
    </div>
  )
}

const Logo = ({ width = 60, height = 60 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 144 125"
      className="animate-spin">
      <defs>
        <linearGradient
          id="logo-small-a"
          x1="-8.1%"
          x2="440.7%"
          y1="48.6%"
          y2="69.6%">
          <stop offset="0%" stopColor="#01A0C6" />
          <stop offset="11.9%" stopColor="#038DBD" />
          <stop offset="32.9%" stopColor="#0673B0" />
          <stop offset="54.4%" stopColor="#0860A8" />
          <stop offset="76.5%" stopColor="#0954A2" />
          <stop offset="100%" stopColor="#0A51A1" />
        </linearGradient>
        <linearGradient
          id="logo-small-b"
          x1="-39.7%"
          x2="409.1%"
          y1="44.6%"
          y2="65.2%">
          <stop offset="0%" stopColor="#01A0C6" />
          <stop offset="11.9%" stopColor="#038DBD" />
          <stop offset="32.9%" stopColor="#0673B0" />
          <stop offset="54.4%" stopColor="#0860A8" />
          <stop offset="76.5%" stopColor="#0954A2" />
          <stop offset="100%" stopColor="#0A51A1" />
        </linearGradient>
      </defs>
      <g fill="none" transform="translate(-1)">
        <polygon
          fill="url(#logo-small-a)"
          points="104.4 71.757 77.715 114.821 42.898 114.821 11.298 61.754 14.554 55.896 10.294 47.304 .994 62.144 37.758 124.244 82.731 124.244 109.131 81.678"
        />
        <polygon
          fill="#FFF"
          points="75.744 102.482 80.314 93.742 55.94 93.742 24.864 39.028 41.6 9.428 47.506 9.428 53.106 .002 36.372 .002 14.558 38.694 50.722 102.482"
        />
        <polygon
          fill="url(#logo-small-b)"
          points="108.237 0 63.265 0 36.865 43.894 41.604 53.155 68.285 9.426 103.102 9.426 134.702 63.818 131.446 70.339 135.706 78.6 145.006 63.429"
        />
        <polygon
          fill="#FFF"
          points="70.257 21.765 65.688 30.505 90.061 30.505 121.136 85.218 104.4 114.818 98.5 114.818 92.9 124.244 109.636 124.244 131.45 85.55 95.279 21.765"
        />
      </g>
    </svg>
  )
}

export default Loading

import { useState, useEffect } from 'react'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

export const useWalletConnectProvider = () => {
    const [provider, setProvider] = useState(null)
    const [connected, setConnected] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const providerOptions = {
            walletconnect: {
                display: {
                    name: 'Mobile'
                },
                package: WalletConnectProvider,
                options: {
                    infuraId: '45d3e01faab843d0aa5de55ea7f11090' // required
                }
            }
        }
        const web3Modal = new Web3Modal({
            network: 'kovan',
            cacheProvider: true,
            theme: 'dark',
            providerOptions // required
        })
        setConnected(web3Modal.cachedProvider !== '')
        setProvider(web3Modal)
        setLoading(false)
    }, [])

    return [provider, loading, connected]
}

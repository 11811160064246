//import { NavLink } from 'react-router-dom'
import React from 'react'

const MobileMenu = () => {
  return (
    <nav className="flex flex-row mx-auto container p-5 items-center justify-between text-white block md:hidden uppercase font-bold md:w-2/3 w-full nav">
      {/*  <NavLink
        to="/staking"
        strict={true}
        exact={true}
        activeClassName="selected">
        Staking
      </NavLink>
      <NavLink to="/lp" strict={true} exact={true} activeClassName="selected">
        LP
      </NavLink> */}
    </nav>
  )
}

export default MobileMenu
